/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

body::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

body::-webkit-scrollbar-thumb {
  background: #556ee6;
  border-radius: 5px;
}

body {
  background: white;
}

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
